import React from 'react';
import Logo from '../components/img/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';





const Temp = () => {

    return(<div className='page-wrapper'>
        <div className='temp-banner'></div>
        <div className='temp-contact'>
            <div className='temp-brand'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-3'>
                            <div className='logo-container'>
                                <img className='logo' src={Logo} alt="SEAGULL Marine Industry" />
                            </div>
                        </div>
                        <div className='col-9'>
                            <div className='brand-name'>
                                <h1>SEAGULL</h1>
                                <span>Marine Industry</span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'></div>
                        <div className='col-9'>
                            <button className='btn btn-primary'>Learn More</button>
                        </div>
                    </div>
                </div>
              
              

               
            </div>
            <div className='contact-form'>
                <span className='title'>Get In Touch</span>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <label className='label-form'>Company *</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <input type='text' name='company' className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <label className='label-form'>Name *</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <input type='text' name='fullname' className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <label className='label-form'>Mobile No. *</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <input type='text' name='mobile' className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <label className='label-form'>Email *</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <input type='text' name='email' className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <label className='label-form'>Message *</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <textarea rows={4} className='form-control' placeholder='Message here' name='message'></textarea>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 form-group'>
                            <button type='submit' className='btn btn-blue'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='location'>
                <span className='title'><FontAwesomeIcon icon={faLocationDot}/> Location</span>  
                <p className='label'>Addax Tower | Office 5712 | Al Reem Island, Abu Dhabi | UAE</p>                 
            </div>

            <div className='contact'>
                <span className='title'><FontAwesomeIcon icon={faPhone}/> Contact</span>
                <p className='label-small'>Name</p>
                <p className='label-value'>Engr. Anil Raveendran</p>
                <p className='label-small'>Phone</p>
                <p className='label-link'>+971 56 883 3843</p>
                <p className='label-small'>Email</p>
                <p className='label-link'>anil@smic.ae</p>
            </div>

        </div>
    </div>);
}

export default Temp;