import React from "react";
import '../components/css/main.scss';
import Temp from '../pages/temp';



const Main = () => {

    return(<div className="main-wrapper">
        <Temp></Temp>
    </div>)

}

export default Main;