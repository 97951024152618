import Main from '../src/layout/main';


function App() {
  return (
    <div className="App">
        <div className="main-wrapper">
          <Main />
            
        </div>
    </div>
  );
}

export default App;
